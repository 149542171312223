.user-dashboard {
    .container-fluid {
        max-width: 1800px;
    }

    .navbar {
        margin-top: 20px;

        .container,
        .container-fluid {
            max-width: 1800px;
            padding: 30px;
            border-radius: 8px;
            box-shadow: 0 3px 20px 5px rgba(0, 0, 0, 0.05);
        }

        .navbar-collapse {
            flex-grow: 0;
        }
    }

    .social-links {
        a {
            text-decoration: none;
            opacity: 0.9;
            transition: all 0.3s ease-in-out;

            &:hover {
                opacity: 1;
            }
        }

        svg {
            height: 40px;
            width: auto;
        }

        .fb {
            margin-right: 10px;
        }
    }

    // new modal styles
    #newDataPreviewModal {
        margin: 0;
        left: 0;
        width: 100%;
        min-height: 100vw;
        height: 100%;
        background-color: #fff;

        .modal-header {
            //position: fixed;
            //right: 100px;
            //left: unset;
            top: 25px;
            bottom: unset;
            height: 40px;
            width: 100%;
            display: inline-block;
            justify-content: flex-end;
            z-index: 999;

            .close {
                height: 40px;
                width: 40px;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                background-color: var(--color-one);

                margin: 0;
                //float: none;
                transition: none;

                span {
                    color: #fff;
                }
            }
        }

        .modal-dialog {
            max-width: 100%;
            width: 100%;
            margin: auto;
            height: 940px;
            overflow-y: hidden;
            margin-top: 30px;

            .modal-content {
                height: 600px;
                overflow: auto;

                @include md {
                    height: 800px;
                }
                @include lg {
                    height: 800px;
                }
                @include xl {
                    height: 900px;
                }

                &::-webkit-scrollbar {
                    width: 10px;
                    border-radius: 8px;
                }

                &::-webkit-scrollbar-track {
                    //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
                    border-radius: 8px;
                    background-color: var(--color-one-light);
                }

                &::-webkit-scrollbar-thumb {
                    background-color: var(--color-one);
                    border-radius: 8px;
                }
            }

            .modal-header {
                max-width: 1000px;
                margin: 0 auto;
            }

            .modal-body {
                max-height: 90vw;
                //max-width: 900px;
                max-width: 750px;
                margin: 0 auto;
            }

            iframe {
                border-radius: 8px;
                display: block;
            }

            div {
                border: 0;
                border-radius: 0;
            }
        }

        .modal-body {
            .title {
                h1,
                h2,
                h3,
                h4,
                h5 {
                    font-weight: bold;
                    font-size: 45px;
                    margin-bottom: 5px;
                }
            }

            .divider {
                height: 6px;
                width: 120px;
                background-color: var(--color-one);
                margin-bottom: 30px;
            }

            .content {
                img {
                    display: block;
                    width: 100%;
                    border-radius: 8px;
                    margin-bottom: 15px;
                }

                p,
                div {
                    font-size: 18px;
                    line-height: 1.5;
                }
            }
        }
    } // modal

    // calander
    #calendar .fc-scroller.fc-scroller-liquid-absolute {
        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--color-one-light);
            border-radius: 8px;
        }
    }
}

#cards-layout {
    #first-column,
    #third-column,
    #second-column {
        .card {
            margin-bottom: 30px;

            iframe {
                max-width: 100%;
                height: auto;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    #second-column {
        .card {
            cursor: pointer;
            border: 1px solid transparent;
            transition: all 0.3s ease-in-out;

            &:hover {
                border: 1px solid var(--color-one);
            }
        }
    }

    #third-column {
        .card {
            @extend .bg-color-light;
            box-shadow: none;
            border: 1px solid var(--color-one-light);
        }
        .card--primary-contact {
            border: 1px solid var(--color-one-light);
        }
        [data-category="before"],
        [data-category="under"],
        [data-category="after"] {
            // border: 1px solid transparent;
            cursor: pointer;
            transition: border 0.3s ease-in-out;

            .card-header {
                background-color: transparent !important;
                box-shadow: none;
            }

            &:hover {
                border: 1px solid var(--color-one);
            }
        }
    }
}

// ===== info cards filter for before/during/after =====
#filter {
    list-style: none;

    .current a {
        @extend .btn-primary;
    }
}

// ===== video preview modal =====
.video-popup-trigger {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    cursor: pointer;
    border: 2px solid var(--color-one-light);
    padding: 8px 15px 8px 10px;
    border-radius: 8px;
    transition: border 0.3s ease-in-out;

    &:hover {
        border-color: var(--color-one);
    }

    .icon {
        margin-right: 10px;
    }

    .text {
        font-weight: bold;
    }

    svg {
        height: 40px;
        width: 40px;

        .path-outer {
            fill: var(--color-one);
        }
    }
}
#videoPreviewModal {
    .modal-dialog {
        max-width: 900px;
        width: 96%;
        margin: 150px auto 0 auto;
    }

    iframe {
        border-radius: 8px;
        display: block;
        height: 265px;

        @include md {
            height: 365px;
        }

        @include lg {
            height: 450px;
        }

        .modal-body {
            padding: 5px;
        }
    }
}
