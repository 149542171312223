.login-page-wrap,
.register-page-wrap {
    .left-col {
        padding: 35px 15px;
        //background-image: url("../images/bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        z-index: 99;
        overflow: hidden;

        @include md {
            height: 100vh;
        }

        .content {
            z-index: 1;
        }
    }

    p {
        font-size: 20px;
    }

    .right-col {
        padding: 35px 15px;
    }
}

.login-form,
.register-form {
    width: 96%;
    max-width: 750px;
}


// .login-page-wrap {
//     position: relative;
//     z-index: 99;

//     .container-fluid {
//         z-index: 1;
//     }
// }
