$screen-sm-min: 576px; // mobiles
$screen-md-min: 768px; // Small tablets (portrait view)
$screen-lg-min: 992px; // Tablets and small desktops
$screen-xl-min: 1200px; // Large tablets and desktops

// mobile devices
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}
// Custom devices
@mixin rwd($screen) {
    @media (min-width: $screen+"px") {
        @content;
    }
}
