// Body
$body-bg: #fff;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;
$font-weight-base: 400;
$font-weight-normal: 600;

// Colors
//$blue: #3490dc;
//$indigo: #6574cd;
//$purple: #9561e2;
//$pink: #f66d9b;
//$red: #e3342f;
//$orange: #f6993f;
//$yellow: #ffed4a;
//$green: #38c172;
//$teal: #4dc0b5;
//$cyan: #6cb2eb;

// site specific colors
$primary: #4d16dd;
$secondary: #ff47f9;

// green beach colors
//$primary: #02aab0;
//$secondary: #00cdac;

/*=======================================
Bootstrap button variables
=======================================*/

$btn-padding-y:               10px;
$btn-padding-x:               30px;
//$btn-font-family:             $input-btn-font-family;
$btn-font-size:               1rem;
//$btn-line-height:             $input-btn-line-height;
//$btn-white-space:             null;

//$btn-padding-y-sm:            $input-btn-padding-y-sm;
//$btn-padding-x-sm:            $input-btn-padding-x-sm;
//$btn-font-size-sm:            $input-btn-font-size-sm;
//$btn-line-height-sm:          $input-btn-line-height-sm;

//$btn-padding-y-lg:            $input-btn-padding-y-lg;
//$btn-padding-x-lg:            $input-btn-padding-x-lg;
//$btn-font-size-lg:            $input-btn-font-size-lg;
//$btn-line-height-lg:          $input-btn-line-height-lg;

//$btn-border-width:            $input-btn-border-width;

//$btn-font-weight:             $font-weight-normal;
//$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075);
//$btn-focus-width:             $input-btn-focus-width;
//$btn-focus-box-shadow:        $input-btn-focus-box-shadow;
//$btn-disabled-opacity:        .65;
//$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125);

//$btn-link-disabled-color:     $gray-600;

//$btn-block-spacing-y:         .5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           8px;
$btn-border-radius-lg:        8px;
$btn-border-radius-sm:        8px;

$border-radius: 8px;
$border-radius-lg: 8px;
$border-radius-sm: 5px;

//$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
/*=======================================
END Bootstrap button variables
=======================================*/

/*=======================================
Custom css variables
=======================================*/
::root {
    --green: #2ed573;
    --blue: #0984e3;
    --yellow: #fdcb6e;
    --red: #ff4757;
    --pink: #e84393;
    --orange: #f65b00;
    --teal: #02aab0;
    --violet: #6c5ce7;
}
.body-red {
    --color-one: #ff4757;
    --color-one-light: rgba(255, 71, 87, 0.3);

    .top-color-bar {
        background-color: var(--color-one);
        background-image: none;
    }

    .bg-color {
        background-color: rgba(#ff4757, .15) !important;
        &-light {
            background-color: rgba(#ff4757, .05) !important;
        }
    }
    a,
    .txt-color {
        color: var(--color-one) !important;
    }
}
.body-orange {
    --color-one: #f65b00;
    --color-one-light: rgba(246, 91, 0, 0.3);

    .top-color-bar {
        background-color: var(--color-one);
        background-image: none;
    }

    .bg-color {
        background-color: rgba(#f65b00, .15) !important;
        &-light {
            background-color: rgba(#f65b00, .05) !important;
        }
    }
    a,
    .txt-color {
        color: var(--color-one) !important;
    }
}
.body-yellow {
    --color-one: #935f00;
    --color-one-light: rgba(147, 95, 0, 0.3);

    .top-color-bar {
        background-color: var(--color-one);
        background-image: none;
    }

    .bg-color {
        background-color: rgba(#fdcb6e, .15) !important;
        &-light {
            background-color: rgba(#fdcb6e, .05) !important;
        }
    }
    a,
    .txt-color {
        color: var(--color-one) !important;
    }
}
.body-violet {
    --color-one: #6c5ce7;
    --color-one-light: rgba(108, 92, 231, 0.3);

    .top-color-bar {
        background-color: var(--color-one);
        background-image: none;
    }

    .bg-color {
        background-color: rgba(#6c5ce7, .15) !important;
        &-light {
            background-color: rgba(#6c5ce7, .05) !important;
        }
    }
    a,
    .txt-color {
        color: var(--color-one) !important;
    }
}
.body-green {
    --color-one: #2ed573;
    --color-one-light: rgba(46, 213, 115, 0.3);

    .top-color-bar {
        background-color: var(--color-one);
        background-image: none;
    }

    .bg-color {
        background-color: rgba(#2ed573, .15) !important;
        &-light {
            background-color: rgba(#2ed573, .05) !important;
        }
    }
    a,
    .txt-color {
        color: var(--color-one) !important;
    }
}
.body-teal {
    --color-one: #02aab0;
    --color-one-light: rgba(2, 170, 176, 0.3);

    .top-color-bar {
        background-color: var(--color-one);
        background-image: none;
    }

    .bg-color {
        background-color: rgba(#02aab0, .15) !important;
        &-light {
            background-color: rgba(#02aab0, .05) !important;
        }
    }
    a,
    .txt-color {
        color: var(--color-one) !important;
    }
}

.body-blue {
    --color-one: #0984e3;
    --color-one-light: rgba(9, 132, 227, 0.3);

    .top-color-bar {
        background-color: var(--color-one);
        background-image: none;
    }

    .bg-color {
        background-color: rgba(#0984e3, .15) !important;
        &-light {
            background-color: rgba(#0984e3, .05) !important;
        }
    }

    a,
    .txt-color {
        color: var(--color-one) !important;
    }
}
.body-pink {
    --color-one: #e84393;
    --color-one-light: rgba(232, 67, 147, 0.3);

    .top-color-bar {
        background-color: var(--color-one);
        background-image: none;
    }

    .bg-color {
        background-color: rgba(#e84393, .15) !important;
        &-light {
            background-color: rgba(#e84393, .05) !important;
        }
    }
    a,
    .txt-color {
        color: var(--color-one) !important;
    }
}
