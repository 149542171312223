/*-----------------------------------
    Fonts
-----------------------------------*/
//@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;600;700;900&display=swap");

// Variables
@import "variables-custom";
/*-----------------------------------
    Bootstrap
-----------------------------------*/
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

/*-----------------------------------
    Custom Scss Styles
-----------------------------------*/
@import "mixins";
@import "helpers";

@import "components/top-bar";
@import "components/card";
@import "components/btn";
@import "components/dropdown";
@import "components/modal";
@import "components/animations";
@import "pages/login-register";
@import "pages/user-dashboard";

.calender-widget {
    .fc-button {
        @media screen and (max-width: 1199px) {
            padding: 2px 7px !important;
            font-size: 15px !important;
        }
    }

    .fc-toolbar-title {
        font-size: 15px !important;

        @include lg {
            font-size: 22px !important;
        }
    }
}

.theme-svg-fill {
    position: relative;

    svg * {
        fill: #198a9d;
    }
}
.notify_sms {
    //position: absolute;
    //right: -5px;
    //top: -10px;
    background-color: #f00;
    color: #fff;
    font-weight: bold;
    height: auto;
    width: auto;
    min-height: 20px;
    min-width: 20px;
    display: inline-block;
    padding: 4px;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.07);
}
