body,
html {
    scroll-behavior: smooth;
}
label {
    font-weight: 600;
}
.table th,
.table td {
    vertical-align: middle;
}
.custom-checkbox {
    cursor: pointer;

    .custom-control-label::before {
        border-radius: 5px;
        cursor: pointer;
    }
    .custom-control-label::after {
        cursor: pointer;
    }
    .custom-control-label:hover::before {
        cursor: pointer;
    }
    .custom-control-label:hover::after {
        cursor: pointer;
    }
}

img {
    max-width: 100%;
}
/* -------- Hide header where necessary -------- */
.hide-header #site-header {
    display: none;
}
/* -------- Letter spacing -------- */
.ls-1 {
    letter-spacing: 1px;
}
.page-title {
    text-transform: capitalize !important;
}
.template {
    display: none;
}

.card-item.hidden {
    display: none;
}
