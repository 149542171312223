.animation-overlay {
    position: absolute;
    // background-color: #fff;
    // height: 100px;
    // width: 100px;
    z-index: -1;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;

    .circle {
        position: absolute;
        left: 0;
        top: 0;
        width: 200px;
        height: 200px;
        border: 1px solid $secondary;
        border-radius: 50%;
        animation-name: zoom-opacity;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        transform-style: preserve-3d;
        will-change: transform;
    }

    .circle-1 {
        left: 12%;
        top: 40%;
    }

    .circle-2 {
        left: 60%;
        top: 70%;
        width: 120px;
        height: 120px;
        animation-delay: .5s;
    }

    .circle-3 {
        left: 75%;
        top: 20%;
        width: 80px;
        height: 80px;
        animation-duration: 6s;
        animation-delay: 1s;
    }

    .xmark {
        position: absolute;
        left: 10%;
        top: 10%;
        height: 24px;
        width: 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-wrap: nowrap;
        
        animation-name: rorateClock;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        transform-style: preserve-3d;
        will-change: transform;

        .h-line,
        .v-line {
            background-color: #e5b2d2;
            position: absolute;
            width: 100%;
            height: 1px;
        }

        .v-line {
            width: 1px;
            height: 100%;
        }

        &-2 {
            top: 50%;
            left: 40%;
        }
        
        &-3 {
            top: 70%;
            left: 30%;
        }

        &-4 {
            top: 90%;
            left: 59%;
        }
    }

    .line {
        position: absolute;
        left: 40%;
        top: 20%;
        height: 1px;
        width: 380px;
        background-color: #7e4fa0;

        transform: rotate(-45deg);
        // transform: translate3d(-1px, 1px, 0px) scale3d(1,1,1) rotateX(0) rotateY(0) rotateZ(-45deg) skew(0deg,0deg);
        transform-style: preserve-3d;
        transform-origin: 100% 100%;
        will-change: transform;

        animation-name: linemove;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

@keyframes zoom-opacity {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    60% {
        opacity: .6;
        transform: scale(.6);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes rorateClock {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes linemove {
    0% {
        transform: translate3d(500px, -500px, 0px) scale3d(1,1,1) rotateX(0) rotateY(0) rotateZ(-45deg) skew(0deg,0deg);
    }

    100% {
        transform: translate3d(-1000px, 1000px, 0)  scale3d(1,1,1) rotateX(0) rotateY(0) rotateZ(-45deg) skew(0deg,0deg);
    }
}


// @keyframes linemoveRGB {
//     0% {
//         transform: translate3d(150px, -150px, 0px) scale3d(1,1,1) rotateX(0) rotateY(0) rotateZ(-45deg) skew(0deg,0deg);
//         background-image: linear-gradient(to right, lightgreen 20%,cornflowerblue 40%,yellow 60%,violet 80%);
//     }

//     25% {
//         transform: translate3d(-150px, 150px, 0px) scale3d(1,1,1) rotateX(0) rotateY(0) rotateZ(-45deg) skew(0deg,0deg);
//         background-image: linear-gradient(to right, violet 20%,lightgreen 40%,cornflowerblue 60%,yellow 80%);
//     }

//     50% {
//         transform: translate3d(-300px, 300px, 0px) scale3d(1,1,1) rotateX(0) rotateY(0) rotateZ(-45deg) skew(0deg,0deg);
//         background-image: linear-gradient(to right, yellow 20%, violet 40%,lightgreen 60%,cornflowerblue 80%);
//     }

//     75% {
//         transform: translate3d(-600px, 600px, 0px) scale3d(1,1,1) rotateX(0) rotateY(0) rotateZ(-45deg) skew(0deg,0deg);
//         background-image: linear-gradient(to right, cornflowerblue 20%,yellow 40%,violet 60%,lightgreen 80%);
//     }

//     100% {
//         transform: translate3d(-1200px, 1200px, 0px) scale3d(1,1,1) rotateX(0) rotateY(0) rotateZ(-45deg) skew(0deg,0deg);
//         background-image: linear-gradient(to right, yellow 20%,violet 40%,lightgreen 60%,cornflowerblue 80%);
//     }
// }