.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    height: 100%;
    transform: translate3d(0%, 0, 0);
    border-radius: 0;
}

.modal-dialog {
    max-width: 650px;
    right: 0;
    left: auto;
    // transform: none !important;
    margin: auto 0 0 auto;
    height: 100%;
    border-radius: 0;
}

.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 0;
}

.modal.right.fade .modal-dialog {
    right: -620px;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
    right: 0;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 600px;
    }
}
